var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader",style:({
    width: `${_vm.size.width}rem`,
    height: `${_vm.size.height}rem`,
    'border-bottom-color': _vm.backdropColor,
    'border-right-color': _vm.backdropColor,
    'border-top-color': _vm.backdropColor,
    'border-left-color': _vm.accentColor,
    'border-bottom-width': `${_vm.thickness}rem`,
    'border-right-width': `${_vm.thickness}rem`,
    'border-top-width': `${_vm.thickness}rem`,
    'border-left-width': `${_vm.thickness}rem`
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }